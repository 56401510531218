import React from 'react'
import { LayoutIR } from '../../components/organisms/Layout/LayoutIR'
import { SEOIR } from '../../components/data/SEO'
import { ENGLISH_TITLE } from '../../components/data/English'

/**
 * ページコンポーネント
 */
/** */
const Page = () => {
  return (
    <LayoutIR
      heading="免責事項"
      headingEnglish={ENGLISH_TITLE.Disclaimer}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '免責事項', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <p className="leading-normal">
          当サイトに掲載されている当社の業績見通し、計画、戦略などは、現時点で入手可能な情報に基づき当社の経営者が判断した、将来の業績に関係する見通しであり、リスクや不確実性を含んでいます。また、当社の事業をとりまく、経済情勢、市場の動向等の様々な重要な要素により、実際の業績は、業績予想とは、異なり得ることを予めご承知おきください。
          <br />
          当サイトのいかなる情報も、当社の株式の購入や売却等を勧誘するものではありません。
          <br />
          投資に関する決定は利用者ご自身の判断でなさるようお願いいたします。
          <br />
          <br />
          当サイトに掲載された情報は予告なく変更や掲載を取りやめることがあります。また、通信環境やお客様のコンピュータの状況、その他の理由により当サイトを正常に利用できない場合があります。
          <br />
          <br />
          当サイトの内容について、当社は万全を期して掲載しておりますが、掲載された情報に誤りがあった場合や第三者によるデータの改ざん等があった場合、またデータのダウンロード等によって生じた障害及び損害については、当社は一切責任を負うものではありませんのであらかじめご了承ください。
        </p>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="免責事項" url="/ir/disclaimer" />
